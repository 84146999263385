import React from "react";
import FooterLogo from "../../images/footer-logo.png";
import { FaFacebookF, FaInstagram, FaYoutube, } from "react-icons/fa";
import { BsWhatsapp } from "react-icons/bs";
import "./style.scss";
import { Link } from "react-router-dom";
import ReactWhatsapp from "react-whatsapp";

const Footer = () => {
  return (
    <>
      <footer>
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="footer-content-block">
                <div className="logo-block">
                  <Link to="/">
                    <img
                      src={FooterLogo}
                      alt={"FooterLogo"}
                      className="footer-logo "
                    />
                  </Link>
                  <ul className="social-link">
                    <li>
                      <a 
                        href="https://www.facebook.com/zamzamsweetsindia"
                        target="_blank"
                      >
                        <FaFacebookF />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.instagram.com/zamzamsweets/"
                        target="_blank"
                      >
                        <FaInstagram />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.youtube.com/@ZamZamSweets1989"
                        target="_blank"
                      >
                        <FaYoutube />
                      </a>
                    </li>
                    <li>
                      <a>
                        <ReactWhatsapp
                          number="7397726622"
                          message="Hello Zam Zam Family!!!"
                        >
                          <BsWhatsapp />
                        </ReactWhatsapp>
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="footer-menu-block">
                  <h2>Locations</h2>
                  <ul>
                    <li>
                      <a target="_blank" href="https://g.co/kgs/WmKBJA">Periyar</a>
                    </li>
                    <li>
                      <a target="_blank" href="https://g.co/kgs/Lcz5EM">Villapuram</a>
                    </li>
                    <li>
                      <a target="_blank" href="https://g.co/kgs/AR6P4U">Anna Nagar</a>
                    </li>
                    <li>
                      <a target="_blank" href="https://g.co/kgs/UhCUzj">K.Pudur</a>
                    </li>
                    <li>
                      <a target="_blank" href="https://maps.app.goo.gl/Ctiiq2YjaqgmmyYT9">Thirunagar</a>
                    </li>
                    <li>
                      <a target="_blank" href="https://g.co/kgs/KDFtM4">Palanganatham</a>
                    </li>
                    <li>
                      <a target="_blank" href="https://g.co/kgs/ekDJAJ">Kalavasal</a>
                    </li>
                    <li>
                      <a target="_blank" href="https://g.co/kgs/csaauu">Kamarajar Salai</a>
                    </li>
                    <li>
                      <a target="_blank" href="https://g.co/kgs/3NpB3u">Koodal Nagar</a>
                    </li>
                    <li>
                      <a target="_blank" href="https://maps.app.goo.gl/zMGojSVwnhLN7WJ46">Otthakadai</a>
                    </li>
                    <li>
                      <a target="_blank" href="https://maps.app.goo.gl/n7tEYcqNL2ti4cw8A">Kappalur Toll</a>
                    </li>
                    <li>
                      <a target="_blank" href="https://maps.app.goo.gl/FiW45LPJbhwLm1Ao9">BB Kulam</a>
                    </li>
                  </ul>
                </div>
                <div className="footer-menu-block">
                  <h2>Links</h2>

                  <ul>
                    <li>
                      <a href="/">Home</a>
                    </li>
                    <li>
                      <a href="/sweets">Sweet</a>
                    </li>
                    <li>
                      <a href="/savouries">Savouries</a>
                    </li>
                    <li>
                      <a href="/aboutus">About Us</a>
                    </li>
                    <li>
                      <a href="/contactus">Contact</a>
                    </li>
                  </ul>
                </div>
                <div className="footer-menu-block">
                  <h2>Company</h2>
                  <ul>
                    <li>
                      <a href="/terms">Terms & Conditions</a>
                    </li>
                     <li>
                      <a href="/policies">Company Policies</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
      <div className="copy-footer">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <h6>
                Copyright © {new Date().getFullYear()}{" "}
                <a href="/">
                  <span>ZamZamSweets</span>
                </a>
                . All Rights Reserved.
              </h6>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
